import Vue from 'vue';
import Router from 'vue-router';
import store from '../_store';
import cookie from "@/_helpers/cookie";
import { hotSpotsList, stateMenuList } from '@/_helpers/hotSpotsActiveMenu';

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/pages/home/Home.vue'),
    meta: {
      layout: 'restyle',
      title: 'Мобильные приложения для Android',
      breadcrumb: [
        {
          name: 'Главная',
        },
      ],
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/Auth/Login.vue'),
    meta: {
      title: 'Логин',
      layout: 'empty',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/pages/Profile'),
    meta: {
      title: 'Редактировать профиль',
      layout: 'restyle',
    },
  },
  {
    path: '/user/:id',
    name: 'user',
    component: () => import('@/pages/User/User'),
    meta: {
      title: 'Профиль пользователя',
      layout: 'restyle',
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Профиль пользователя',
        },
      ],
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/pages/Auth/ForgotPassword'),
    meta: {
      layout: 'empty',
      title: 'Забыли пароль?',
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/pages/Auth/ResetPassword'),
    meta: {
      layout: 'empty',
      title: 'Восстановление пароля',
    },
  },
  {
    path: '/roles',
    name: 'role_all',
    component: () => import('@/pages/Role/Roles'),
    meta: {
      layout: 'restyle',
      title: 'Роли',
      active: 1,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Роли',
        },
      ],
      section: 'admin',
      titleMenu: 'Роли',
    },
  },
  {
    path: '/organisations',
    name: 'organisations',
    component: () => import('@/pages/Organisations/Organisations'),
    meta: {
      layout: 'restyle',
      title: 'Организации',
      active: 1,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Организации',
        },
      ],
      section: 'admin',
      titleMenu: 'Организации',
    },
  },
  {
    path: '/users',
    name: 'user_all',
    component: () => import('@/pages/Reference/Users/Users'),
    meta: {
      layout: 'restyle',
      title: 'Пользователи',
      active: 1,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Пользователи',
        },
      ],
      section: 'admin',
      titleMenu: 'Пользователи',
    },
  },
  {
    path: '/users-mobile',
    name: 'mobile-user_all',
    component: () => import('@/pages/Reference/UsersMobile/UsersMobile'),
    meta: {
      layout: 'restyle-mobile',
      title: 'Пользователи',
      active: 1,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Пользователи',
        },
      ],
      section: 'admin',
    },
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: () =>
      import('@/pages/Inventory_restyle/Inventory_map/Inventory_map'),
    meta: {
      layout: 'restyle',
      title: 'Инвентаризация',
      active: 2,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Инвентаризация',
        },
      ],
      section: 'inventory',
      titleMenu: 'Инвентаризация',
    },
  },
  {
    path: '/inventory/platform',
    name: 'inventory_platform',
    component: () =>
      import(
        '@/pages/Inventory_restyle/Inventory_platforms/Inventory_platforms'
      ),
    meta: {
      layout: 'restyle',
      title: 'Контейнерные площадки',
      active: 2,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Инвентаризация контейнерные площадки',
        },
      ],
      section: 'inventory',
      titleMenu: 'Контейнерные площадки',
    },
  },
  {
    path: '/inventory/platform/:id',
    name: 'inventory_platform_view',
    component: () =>
      import(
        '@/pages/Inventory_restyle/Inventory_platforms/Inventory_detail_platform/Inventory_detail_platform'
      ),
    meta: {
      layout: 'restyle',
      title: 'Контейнерная площадка',
      active: 2,
      teleport: true,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Контейнерная площадка',
        },
      ],
      section: 'inventory',
    },
  },
  {
    path: '/reference/container-type',
    name: 'container_type',
    component: () => import('@/pages/Reference/ContainerType'),
    meta: {
      layout: 'restyle',
      title: 'Типы контейнеров',
      active: 1,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Справочники',
        },
        {
          name: 'Типы контейнеров',
        },
      ],
      section: 'reference',
      titleMenu: 'Типы контейнеров',
    },
  },
  {
    path: '/reference/garbage-type',
    name: 'garbage_type',
    component: () => import('@/pages/Reference/garbage_types/WasteTypes'),
    meta: {
      layout: 'restyle',
      title: 'Типы отходов',
      active: 1,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Справочники',
        },
        {
          name: 'Типы отходов',
        },
      ],
      section: 'reference',
      titleMenu: 'Типы отходов',
    },
  },
  {
    path: '/reference/reasons-stopped-container',
    name: 'reasons_stopped_container',
    component: () =>
      import(
        '@/pages/Reference/reasons_stopped_container/ReasonsStoppedContainer'
      ),
    meta: {
      layout: 'restyle',
      title: 'Причины приостановки контейнеров',
      active: 1,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Справочники',
        },
        {
          name: 'Причины приостановки контейнеров',
        },
      ],
      section: 'reference',
      titleMenu: 'Причины приостановки контейнеров',
    },
  },
  {
    path: '/platform-type',
    name: 'platform_type',
    component: () => import('@/pages/Reference/PlatformType'),
    meta: {
      layout: 'restyle',
      title: 'Типы контейнерных площадок',
      active: 1,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Справочники',
        },
        {
          name: 'Типы контейнерных площадок',
        },
      ],
      section: 'reference',
      titleMenu: 'Типы контейнерных площадок',
    },
  },
  {
    path: '/failure-reason',
    name: 'failure_reason',
    component: () => import('@/pages/Reference/Failure/FailureReason'),
    meta: {
      layout: 'restyle',
      title: 'Причины невывозов',
      active: 1,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Справочники',
        },
        {
          name: 'Причины невывоза',
        },
      ],
      section: 'reference',
      titleMenu: 'Причины невывозов',
    },
  },
  {
    path: '/early-completion',
    name: 'early completion',
    component: () =>
      import('@/pages/Reference/EarlyCompletion/EarlyCompletion'),
    meta: {
      layout: 'restyle',
      title: 'Причины досрочного завершения рейса',
      active: 1,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Справочники',
        },
        {
          name: 'Причины досрочного завершения рейса',
        },
      ],
      section: 'reference',
      titleMenu: 'Причины досрочного завершения рейса',
    },
  },
  {
    path: '/breakdown-type',
    name: 'breakdown_type',
    component: () => import('@/pages/Reference/BreakDown/BreakdownType'),
    meta: {
      layout: 'restyle',
      title: 'Виды поломок',
      active: 1,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Справочники',
        },
        {
          name: 'Виды поломок',
        },
      ],
      section: 'reference',
      titleMenu: 'Виды поломок',
    },
  },
  {
    path: '/weight-cancel-reason',
    name: 'weight_cancel_reason',
    component: () =>
      import('@/pages/Reference/WeightCancelReason/WeightCancelReason'),
    meta: {
      layout: 'restyle',
      title: 'Причины отмены взвешиваний',
      active: 1,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Справочники',
        },
        {
          name: 'Причины отмены взвешиваний',
        },
      ],
      section: 'reference',
      titleMenu: 'Причины отмены взвешиваний',
    },
  },
  {
    path: '/carpark/vehicle',
    name: 'vehicle',
    component: () => import('@/pages/Carpark/Vehicles'),
    meta: {
      layout: 'restyle',
      title: 'Автомобили',
      active: 3,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Автопарк',
        },
        {
          name: 'Автомобили',
        },
      ],
      section: 'carpark',
      titleMenu: 'Автомобили',
    },
  },
  {
    path: '/carpark/vehicle_mobile',
    name: 'mobile_vehicle',
    component: () => import('@/pages/CarparkMobile/VehiclesMobile.vue'),
    meta: {
      layout: 'restyle-mobile',
      title: 'Автомобили',
      active: 3,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Автопарк',
        },
        {
          name: 'Автомобили',
        },
      ],
      section: 'carpark',
    },
  },
  {
    path: '/carpark/vehicle/camera/:filling_sensor_id',
    name: 'vehicle_camera',
    props: true,
    component: () => import('@/pages/Carpark/Camera'),
    meta: {
      layout: 'restyle',
      title: 'Камеры машины',
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Автопарк',
        },
        {
          name: 'Автомобили',
        },
        {
          name: 'Камеры',
        },
      ],
      section: 'carpark',
    },
  },
  {
    path: '/carpark/sensor/:id',
    name: 'sensorID',
    component: () => import('@/pages/Carpark/Sensor'),
    meta: {
      layout: 'restyle',
      title: 'Датчик заполненности',
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Автопарк',
        },
        {
          name: 'Датчик заполненности машины',
        },
      ],
      section: 'carpark',
    },
  },
  {
    path: '/carpark/statistic/:vehicle_id',
    name: 'statistic_vehicle',
    component: () => import('@/pages/Carpark/vehicle_statistic/statistic.vue'),
    meta: {
      layout: 'restyle',
      title: 'График заполненности',
      teleport: true,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Автопарк',
        },
        {
          name: 'График заполненности',
        },
      ],
      section: 'carpark',
    },
  },
  {
    path: '/fact/online-monitoring',
    name: 'online_monitoring',
    component: () => import('@/pages/OnlineMonitoring/OnlineMonitoring'),
    meta: {
      layout: 'restyle',
      title: 'Онлайн мониторинг',
      active: 3,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Онлайн мониторинг',
        },
      ],
      section: 'fact',
      titleMenu: 'Онлайн мониторинг',
    },
  },
  {
    path: '/fact/online-monitoring-photo',
    name: 'online_monitoring_photo',
    component: () =>
      import('@/pages/OnlineMonitoringPhoto/OnlineMonitoringPhoto'),
    meta: {
      layout: 'restyle',
      title: 'Фотографии',
      active: 3,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Фотографии',
        },
      ],
      section: 'fact',
      titleMenu: 'Фотографии',
    },
  },
  {
    path: '/technical_department/sensors',
    name: 'vehicle_sensor',
    component: () => import('@/pages/technicalDepartment/VehicleSensor'),
    meta: {
      layout: 'restyle',
      title: `Все Gserver'a`,
      active: 5,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Технический отдел',
        },
        {
          name: `Все Gserver'a`,
        },
      ],
      section: 'technicalDepartment',
      titleMenu: `Все Gserver'a`,
    },
  },
  {
    path: '/technical_department/sensors/:id',
    name: 'sensor',
    component: () => import('@/pages/technicalDepartment/sensor'),
    meta: {
      layout: 'restyle',
      title: 'Gserver',
      active: 5,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Технический отдел',
        },
        {
          name: 'Gserver',
        },
      ],
      section: 'technicalDepartment',
    },
  },
  {
    path: '/technical_department/all_list_sensor_components',
    name: 'all_list_sensor_components',
    component: () =>
      import('@/pages/technicalDepartment/all_list_sensor_components/'),
    meta: {
      layout: 'restyle',
      title: 'Компоненты для gserver',
      active: 5,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Технический отдел',
        },
        {
          name: 'Компоненты для gserver',
        },
      ],
      section: 'technicalDepartment',
      titleMenu: 'Компоненты для gserver',
    },
  },
  {
    path: '/technical_department/install_documents',
    name: 'install_documents',
    component: () =>
      import('@/pages/technicalDepartment/Install_documents/Install_documents'),
    meta: {
      layout: 'restyle',
      title: `Документы для установщиков`,
      active: 5,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Технический отдел',
        },
        {
          name: `Документы для установщиков`,
        },
      ],
      section: 'technicalDepartment',
      titleMenu: 'Документы для установщиков',
    },
  },
  {
    path: '/technical_department/install_documents/:id',
    name: 'detail_document',
    component: () =>
      import(
        '@/pages/technicalDepartment/Install_documents/Detail_document/Detail_document'
      ),
    meta: {
      layout: 'restyle',
      title: `Поля документа`,
      active: 5,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Технический отдел',
        },
        {
          name: `Поля документа`,
        },
      ],
      section: 'technicalDepartment',
    },
  },
  {
    path: '/technical_department/install_documents/:id/paragraph/:paragraph_id',
    name: 'paragraph_document',
    component: () =>
      import(
        '@/pages/technicalDepartment/Install_documents/Detail_document/Paragraph_instruction/Paragraph_instruction'
      ),
    meta: {
      layout: 'restyle',
      title: `Параграф документа`,
      active: 5,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Технический отдел',
        },
        {
          name: `Детальная документа`,
        },
        {
          name: `Параграф документа`,
        },
      ],
      section: 'technicalDepartment',
    },
  },
  {
    path: '/technical_department/instruction_documents',
    name: 'instruction_documents',
    component: () =>
      import(
        '@/pages/technicalDepartment/Install_documents/Instruction_documents/Instruction_documents'
      ),
    meta: {
      layout: 'restyle',
      title: `Инструкции`,
      active: 5,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Технический отдел',
        },
        {
          name: `Инструкции`,
        },
      ],
      section: 'technicalDepartment',
      titleMenu: 'Инструкции',
    },
  },
  {
    path: '/technical_department/instruction_documents/:instruction_id',
    name: 'detail_instruction',
    component: () =>
      import(
        '@/pages/technicalDepartment/Install_documents/Instruction_documents/Detail_instruction/Detail_instruction'
      ),
    meta: {
      layout: 'restyle',
      title: `Детальная информация о инструкции`,
      active: 5,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Технический отдел',
        },
        {
          name: `Детальная информация о инструкции`,
        },
      ],
      section: 'technicalDepartment',
      titleMenu: 'Детальная информация о инструкции',
    },
  },
  {
    path: '/technical_department/fichas_update',
    name: 'fichas_update',
    component: () =>
      import('@/pages/technicalDepartment/Fichas_update/Fichas_update'),
    meta: {
      layout: 'restyle',
      title: `Фичи для добавления`,
      active: 5,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Технический отдел',
        },
        {
          name: `Фичи для добавления`,
        },
      ],
      section: 'technicalDepartment',
      titleMenu: 'Фичи для добавления',
    },
  },
  {
    path: '/technical_department/task_installer',
    name: 'task_installer',
    component: () =>
      import('@/pages/technicalDepartment/Task_installer/Task_installer'),
    meta: {
      layout: 'restyle',
      title: `Задачи установщика`,
      active: 5,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Технический отдел',
        },
        {
          name: `Задачи установщика`,
        },
      ],
      section: 'technicalDepartment',
      titleMenu: 'Задачи установщика',
    },
  },
  {
    path: '/technical_department/command_gserver',
    name: 'command_gserver',
    component: () =>
      import('@/pages/technicalDepartment/Command_gserver/Command_gserver'),
    meta: {
      layout: 'restyle',
      title: `Команды Gserver`,
      active: 5,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Технический отдел',
        },
        {
          name: `Команды Gserver`,
        },
      ],
      section: 'technicalDepartment',
      titleMenu: 'Команды Gserver',
    },
  },
  {
    path: '/technical_department/list_logs',
    name: 'list_logs',
    component: () => import('@/pages/technicalDepartment/list_logs/list'),
    meta: {
      layout: 'restyle',
      title: `Список логов`,
      active: 1,
      section: 'technicalDepartment',
      titleMenu: 'Список логов',
    },
  },
  {
    path: '/technical_department/organisation_settings/contentOrganisationSettings',
    name: 'organisation_settings',
    component: () =>
      import(
        '@/pages/technicalDepartment/organisation_settings/contentOrganisationSettings'
      ),
    meta: {
      layout: 'restyle',
      title: `Настройки организации`,
      active: 1,
      section: 'technicalDepartment',
      titleMenu: 'Настройки организации',
    },
  },
  {
    path: '/logistic/CreatePolygon',
    name: 'createPolygon',
    component: () => import('@/pages/logistic/polygon/CreatePolygon'),
    meta: {
      layout: 'restyle',
      title: 'Создание полигона',
      active: 4,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Логистика',
        },
        {
          name: 'Создание полигона',
        },
      ],
      section: 'logistic',
    },
  },
  {
    path: '/logistic/EditPolygon/:id',
    name: 'EditPolygon',
    component: () => import('@/pages/logistic/polygon/EditPolygon'),
    meta: {
      layout: 'restyle',
      title: 'Редактирование полигона',
      active: 4,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Логистика',
        },
        {
          name: 'Редактирование полигона',
        },
      ],
      section: 'logistic',
    },
  },
  {
    path: '/logistic/polygon',
    name: 'polygon',
    component: () => import('@/pages/logistic/polygon/Polygon'),
    meta: {
      layout: 'restyle',
      title: 'Полигоны',
      active: 4,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Логистика',
        },
        {
          name: 'Полигоны',
        },
      ],
      section: 'logistic',
      titleMenu: 'Полигоны',
    },
  },
  {
    path: '/Integration',
    name: 'Integration',
    props: true,
    component: () =>
      import('@/pages/Integrations/Integration_rgSoft/Integration'),
    children: [
      {
        path: 'Integration_vehicles',
        name: 'Integration_vehicles',
        props: true,
        component: () =>
          import(
            '@/pages/Integrations/Integration_rgSoft/Integration_vehicles'
          ),
        meta: {
          layout: 'restyle',
          title: 'Интеграция c РГсофт',
          active: 5,
          breadcrumb: [
            {
              name: 'Главная',
              link: '/',
            },
            {
              name: 'Интеграция c РГсофт',
            },
          ],
          section: 'Integration',
        },
      },
      {
        path: 'Integration_fact_waybills',
        name: 'Integration_fact_waybills',
        props: true,
        component: () =>
          import(
            '@/pages/Integrations/Integration_rgSoft/Integration_fact_waybills'
          ),
        meta: {
          layout: 'restyle',
          title: 'Интеграция c РГсофт',
          active: 5,
          breadcrumb: [
            {
              name: 'Главная',
              link: '/',
            },
            {
              name: 'Интеграция c РГсофт',
            },
          ],
          section: 'Integration',
        },
      },
      {
        path: 'Integration_containers_type',
        name: 'Integration_containers_type',
        props: true,
        component: () =>
          import(
            '@/pages/Integrations/Integration_rgSoft/Integration_containers_type'
          ),
        meta: {
          layout: 'restyle',
          title: 'Интеграция c РГсофт',
          active: 5,
          breadcrumb: [
            {
              name: 'Главная',
              link: '/',
            },
            {
              name: 'Интеграция c РГсофт',
            },
          ],
          section: 'Integration',
        },
      },
    ],
    meta: {
      layout: 'restyle',
      title: 'Интеграция c РГсофт',
      active: 5,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Интеграция c РГсофт',
        },
      ],
      section: 'Integration',
      titleMenu: 'РГСОФТ',
    },
  },
  {
    path: '/Integrations',
    name: 'Integrations',
    component: () =>
      import(
        '@/pages/Integrations/IntegrationAvtospecbaza/IntegrationAvtospecbaza'
      ),
    meta: {
      layout: 'restyle',
      title: 'Интеграции',
      active: 5,
      section: 'Integration',
      titleMenu: 'Интеграции',
    },
  },
  {
    path: '/Integration_element_technology',
    name: 'Integration_element_technology',
    component: () =>
      import(
        '@/pages/Integrations/IntegrationElementTechnology/IntegrationElementTechnology'
      ),
    meta: {
      layout: 'restyle',
      title: 'Интеграция с ДУН',
      active: 5,
      section: 'Integration',
      titleMenu: 'Интеграция с ДУН',
    },
  },
  {
    path: '/fact/mobile/',
    name: 'mobile',
    component: () => import('@/pages/fact/mobile'),
    meta: {
      title: 'Онлайн мониторинг',
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Mobile',
        },
        {
          name: 'Онлайн мониторинг',
        },
      ],
      section: 'fact',
    },
    children: [
      {
        path: 'om',
        name: 'mobile_om',
        props: true,
        component: () => import('@/pages/fact/mobile/om'),
        meta: {
          title: 'Мобильная карта',
          breadcrumb: [
            {
              name: 'Главная',
              link: '/',
            },
            {
              name: 'Mobile',
            },
            {
              name: 'Карта',
            },
          ],
          section: 'fact',
        },
      },
      {
        path: 'om_vehicle',
        name: 'mobile_om_vehicle',
        props: true,
        component: () => import('@/pages/fact/mobile/om_vehicle'),
        meta: {
          title: 'Мобильная карта машин',
          breadcrumb: [
            {
              name: 'Главная',
              link: '/',
            },
            {
              name: 'Mobile',
            },
            {
              name: 'Карта машин',
            },
          ],
          section: 'fact',
        },
      },
      {
        path: 'data_vehicles',
        name: 'mobile_data_vehicles',
        props: true,
        component: () =>
          import('@/pages/fact/mobile/data_vehicles/data_vehicles'),
        meta: {
          title: 'Данные по машинам',
          breadcrumb: [
            {
              name: 'Главная',
              link: '/',
            },
            {
              name: 'Mobile',
            },
            {
              name: 'Карта машин',
            },
          ],
          section: 'fact',
        },
      },
      {
        path: 'incidents',
        name: 'mobile_incidents',
        props: true,
        component: () => import('@/pages/fact/mobile/incidents'),
        meta: {
          title: 'Инциденты',
          breadcrumb: [
            {
              name: 'Главная',
              link: '/',
            },
            {
              name: 'Mobile',
            },
            {
              name: 'Инциденты',
            },
          ],
          section: 'fact',
        },
      },
      {
        path: 'statistics',
        name: 'mobile_statistics',
        props: true,
        component: () => import('@/pages/fact/mobile/statistics'),
        meta: {
          title: 'Статистика',
          breadcrumb: [
            {
              name: 'Главная',
              link: '/',
            },
            {
              name: 'Mobile',
            },
            {
              name: 'Статистика',
            },
          ],
          section: 'fact',
        },
      },
    ],
  },
  {
    path: '/transfer_shift_task/:id',
    name: 'transfer_shift_task',
    redirect: (to) => {
      return { name: 'transfer_shift_task_map' };
    },
    component: () => import('@/pages/Transfer_shift_task/Transfer_shift_task'),
    meta: {
      title: 'Перенос КП из СЗ в СЗ',
      layout: 'restyle',
      teleport: true,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Перенос КП из СЗ в СЗ',
        },
      ],
      section: 'transfer',
    },
    children: [
      {
        path: 'map',
        name: 'transfer_shift_task_map',
        component: () =>
          import(
            '@/pages/Transfer_shift_task/Transfer_shift_task_map/Transfer_shift_task_map'
          ),
        meta: {
          title: 'Перенос КП из СЗ в СЗ',
          layout: 'restyle',
          teleport: true,
          breadcrumb: [
            {
              name: 'Главная',
              link: '/',
            },
            {
              name: 'Перенос КП из СЗ в СЗ',
            },
            {
              name: 'Карта',
            },
          ],
          section: 'transfer',
        },
      },
      {
        path: 'table',
        name: 'transfer_shift_task_table',
        component: () =>
          import(
            '@/pages/Transfer_shift_task/Transfer_shift_task_table/Transfer_shift_task_table'
          ),
        meta: {
          title: 'Перенос КП из СЗ в СЗ',
          layout: 'restyle',
          teleport: true,
          breadcrumb: [
            {
              name: 'Главная',
              link: '/',
            },
            {
              name: 'Перенос КП из СЗ в СЗ',
            },
            {
              name: 'Список',
            },
          ],
          section: 'transfer',
        },
      },
    ],
  },
  {
    path: '/list_sensor_containers',
    name: 'list_sensor_containers',
    component: () =>
      import('@/pages/List_sensor_containers/List_sensor_containers'),
    meta: {
      title: 'Список контейнеров с датчиками заполненности',
      active: 1,
      layout: 'restyle',
      teleport: false,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Перенос КП из СЗ в СЗ',
        },
      ],
      section: 'list_sensor_containers',
      titleMenu: 'Список контейнеров с датчиками заполненности',
    },
  },
  {
    path: '/list_sensor_containers/statistic_container/:id',
    name: 'statistic_container',
    component: () =>
      import(
        '@/pages/List_sensor_containers/Statistic_container/Statistic_container'
      ),
    meta: {
      title: 'Статистика датчика в контейнере',
      active: 1,
      layout: 'restyle',
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Перенос КП из СЗ в СЗ',
        },
        {
          name: 'Статистика датчика в контейнере',
        },
      ],
      section: 'statistic_container',
    },
  },
  {
    path: '*',
    component: () => import('@/pages/error/PageNotFound'),
    meta: {
      layout: 'restyle',
      title: 'Мобильные приложения для Android',
      breadcrumb: [
        {
          name: 'Главная',
        },
      ],
    },
  },
  {
    path: '/demo-gserver',
    name: 'demo_gserver',
    component: () => import('@/pages/demo/gserver/gserver.vue'),
    meta: {
      title: 'Демо',
      layout: 'empty',
    },
  },
  {
    path: '/boss-app',
    name: 'boss_app_instruction',
    component: () =>
      import('@/pages/Boss_app_instruction/Boss_app_instruction'),
    meta: {
      title: 'Как установить',
      layout: 'empty',
    },
  },
  {
    path: '/test_boss',
    name: 'birthday_boss',
    component: () => import('@/pages/birthday_boss/birthday'),
    meta: {
      title: 'Серьёзный опрос',
      layout: 'empty',
    },
  },
  {
    path: '/online-list',
    name: 'online_list',
    component: () => import('@/pages/OnlineList/OnlineList.vue'),
    meta: {
      title: 'Онлайн список',
      layout: 'restyle',
      teleport: true,
      noPadding: true,
      titleMenu: 'Онлайн список',
    },
  },
  {
    path: '/fact/online-monitoring-photo-test',
    name: 'online_monitoring_photo-test',
    component: () =>
      import('@/pages/OnlineMonitoringPhotoTest/OnlineMonitoringPhoto'),
    meta: {
      layout: 'restyle',
      title: 'Фотографии',
      active: 3,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Фотографии',
        },
      ],
      section: 'fact',
    },
  },
  {
    path: '/reference/reasons-for-the-application',
    name: 'reasons_for_the_application',
    component: () =>
      import(
        '@/pages/Reference/reasonsForTheApplication/ReasonsForTheApplication'
      ),
    meta: {
      layout: 'restyle',
      title: 'Причины заявки',
      active: 1,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Справочники',
        },
        {
          name: 'Причины заявки',
        },
      ],
      section: 'reference',
      titleMenu: 'Причины заявки',
    },
  },
  {
    path: '/remove-account',
    name: 'remove-account',
    component: () => import('@/pages/remove_account/RemoveAccountPage'),
    meta: {
      layout: 'empty',
      title: 'Удаление аккаунта',
    },
  },
  {
    path: '/confirm/:hash',
    name: 'remove-account-confirm',
    component: () => import('@/pages/remove_account/RemoveAccountPage'),
    meta: {
      layout: 'empty',
      title: 'Удаление аккаунта',
    },
  },
  {
    path: '/navigation_systems',
    name: 'navigation_systems',
    component: () =>
      import('@/pages/Reference/navigation_systems/NavigationSystems.vue'),
    meta: {
      layout: 'restyle',
      title: 'Навигация',
      active: 1,
      section: 'reference',
      titleMenu: 'Навигация',
    },
  },
  {
    path: '/report-work-drivers',
    name: 'report-work-drivers',
    component: () => import('@/pages/reports/report-work-drivers.vue'),
    meta: {
      layout: 'restyle',
      title: 'Отчёты',
      active: 6,
      breadcrumb: [],
      section: 'report-work-drivers',
      titleMenu: 'Отчёт по работе водителей',
    },
  },
  {
    path: '/divisions_organisation',
    name: 'divisions_organisation',
    component: () =>
      import(
        '@/pages/Reference/DivisionsOrganinsation/DivisionsOrganinsation.vue'
      ),
    meta: {
      layout: 'restyle',
      title: 'Подразделения',
      active: 1,
      breadcrumb: [],
      section: 'reference',
      titleMenu: 'Подразделения',
    },
  },
  {
    path: '/fact/monitoring-vehicle',
    name: 'monitoring_vehicle',
    component: () => import('@/pages/MonitoringVehicle/MonitoringVehicle'),
    meta: {
      layout: 'restyle',
      title: 'Мониторинг автомобилей',
      active: 3,
      breadcrumb: [
        {
          name: 'Главная',
          link: '/',
        },
        {
          name: 'Мониторинг автомобилей',
        },
      ],
      section: 'fact',
      titleMenu: 'Мониторинг автомобилей',
    },
  },
  {
    path: '/technical_department/hot_spots',
    name: 'hot_spots',
    component: () => import('@/pages/technicalDepartment/hotSpots/hotSpot'),
    meta: {
      layout: 'restyle',
      title: `Редактирование хотспотов`,
    },
  },
  {
    path: 'report-delay',
    name: 'report_delay',
    component: () => import('@/pages/Report_delay/Report_delay.vue'),
    meta: {
      layout: 'restyle',
      title: 'Отчёты',
      active: 6,
      breadcrumb: [],
      section: 'report-work-drivers',
      titleMenu: 'Отчёт по опозданиям',
    },
  },
];

let hotspotRoutes = routes.filter((item) => {
  return hotSpotsList.indexOf(item.name) > -1;
}).map(item => {
  return {
    routeName: item.name,
    titleMenu: item.meta.titleMenu,
    value: item.meta.titleMenu,
    label: item.meta.titleMenu,
  };
});

hotspotRoutes = [...hotspotRoutes, ...stateMenuList];

store.commit('hotspots/setHotSpotRoutes', hotspotRoutes);

export const router = new Router({
	mode: 'history',
	routes: routes
});

// редирект на авторизацию если пользователь не залогинен
router.beforeEach((to, from, next) => {
  const publicPages = ['login', 'forgot-password', 'reset-password', 'boss_app_instruction', 'birthday_boss', 'remove-account', 'remove-account-confirm']; // массив разрешенных внешних страниц
	const authRequired = !publicPages.includes(to.name);
  if (cookie.getCookie('auth_token')) {
    cookie.updateCookie();
    store.commit('auth/authSuccess');
    store.commit('auth/auth', true);
    store.dispatch('auth/send', false);
    store.dispatch('user/setUser');

    if (!authRequired) {
      return next();
    }
  } else {
      if (authRequired) {
      store.dispatch("auth/logout");
      return next('/login?redirect=' + from.fullPath);
    }
  }
	const loggedIn = localStorage.getItem('auth_token');
  
	if (authRequired && !loggedIn) {
		if (!publicPages.includes(from.name)) {
			return next('/login?redirect=' + from.fullPath);
    }
		else {
			return next('/login');
    }
	}
  //не даем зайти на страницу другого пользователя через url
  const userId = store.getters['user/user'].id;
  if (to.name === 'user' && to.params.id != userId && userId) {
    return next({ name: 'user', params: { id: userId } });
  }
	next();
});
