import Vue from 'vue';
import Vuex from 'vuex';

import {alert} from './alert.module';
import {auth} from './auth.module';
import {users} from './users.module';
import {user} from './user.module'
import {roles} from './roles.module'
import {permissions} from "./permissions.module";
import {organisations} from "./organisations.module";
import {cards} from "./cards.module";
import {platformTypes} from "./platform-types.module";
import {containers} from './containers.module';
import {garbageTruck} from './garbageTruck.module';
import { tracksGserver } from './tracksGserver.module';
import {navigation} from './navigation.module';
import {online_monitoring_full} from './online_monitoring_full';
import {wasteTypes} from './waste-types';
import { omFullWorkOrders } from './omFullWorkOrders';
import { om_photo } from './om_photo';
import { avatar } from './avatar.module';
import { vehicles } from './vehicles.module';
import { transfer_shift_task } from './transfer_shift_task.module';
import { loader } from './loader.module';
import { error } from './error.module';
import { inventory_edited_platform } from './inventory_edited_platform.module';
import { inventory_query } from './inventory_query.module';
import { tooltip_store } from './tooltip_store.module';
import { new_year_store } from './new_year.module';
import { online_list } from './online_list.module';
import { online_list_failures } from './online_list/failures.module';
import { online_list_events } from './online_list/events.module';
import { online_monitoring } from './online_monitoring';
import { mobile_menu } from './mobile_menu.module';
import { user_notifications } from './user_notifications.module';
import { hotspots } from './hotspots.module';
import { tutorial } from './tutorial.module';
import { wbConnect } from './wb_connect.store';
import { hoverStateTopMenu } from './hoverStateTopMenu.module';

Vue.use(Vuex);

const nav = localStorage.getItem('nav');

export default new Vuex.Store({
    state: {
        showNav: (nav),
        userMenu: false,
        logo: 'SmartRO',
        logo_sm: 'SR',
        isVisibleIconClose: false,
    },
    modules: {
        alert,
        auth,
        users,
        user,
        roles,
        permissions,
        organisations,
        cards,
        containers,
        platformTypes,
        garbageTruck,
        online_monitoring_full,
        navigation,
        wasteTypes,
        omFullWorkOrders,
        om_photo,
        avatar,
        vehicles,
        transfer_shift_task,
        loader,
        error,
        tracksGserver,
        inventory_edited_platform,
        tooltip_store,
        online_list,
        inventory_query,
        new_year_store,
        online_list_failures,
        online_list_events,
        online_monitoring,
        mobile_menu,
        user_notifications,
        hotspots,
        tutorial,
        wbConnect,
        hoverStateTopMenu
    },
    mutations: {
        CHANGE_SHOW_NAV: state => {
            state.showNav = !state.showNav;
            if(state.showNav) {
                localStorage.setItem('nav', 'nav');
                state.isVisibleIconClose = true;
            }
            else {
                localStorage.removeItem('nav');
                state.isVisibleIconClose = false;
            }
        },
        CHANGE_USER_MENU: state => {
            state.userMenu = !state.userMenu;
        },
        CLOSE_NAV: state => {
            state.showNav = false;
            localStorage.removeItem('nav');
            state.isVisibleIconClose = false;
        },
        CLOSE_USER_MENU: state => {
            state.userMenu = false
        },
        SET_ROLES(state, roles) {
            state.roles = roles;
        }
    },
    actions: {
        show_nav({commit}) {
            commit('CHANGE_SHOW_NAV');
            if(this.userMenu)
                commit('CLOSE_USER_MENU');
        },
        close_nav({commit}) {
            commit('CLOSE_NAV')
        },
        show_user_menu({commit}) {
            commit('CHANGE_USER_MENU');
            if(this.showNav)
                commit('CLOSE_NAV');
        },
        close_user_menu({commit}) {
            commit('CLOSE_USER_MENU')
        },
        close_all({commit}) {
            commit('CLOSE_NAV');
            commit('CLOSE_USER_MENU');
        },
        rolesSuccess({commit}, roles) {
            commit('SET_ROLES', roles)
        }
    },
    getters: {
        showNavCode(state) {
            return state.showNav ? 1 : 0;
        }
    }
});
